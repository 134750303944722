import React from "react";

const style = {
  img: {
    maxWidth: '100%'
  }
}

const Group = ({apiEndpoint, entity }) => {
  const relation = entity.relationships.picture.data;
  const url = relation.meta.derivatives.crop_2x1.url;
  return (
    <div>
      <img src={`${apiEndpoint}${url}`} alt={entity.attributes.name} style={style.img} />
    </div>
  );
};

export default Group;
