import React from "react";

const icon_url = `https://s-engros.dk/wp-content/themes/fsd/elements/tilbud/icons/`;

const styles = {
  filterWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: -15,
    marginRigth: -15
  },
  label: {
    display: 'inline-block',
    cursor: 'pointer',
    width: '33.3333%',
    paddingLeft: 15,
    paddingRight: 15,
    margin: '10px 0',
    textAlign: 'center',
  },
  input: {
    display: 'none',
  },
  title: {
    textAlign: 'center'
  },
  header: {
    cursor: 'pointer',
    textAlign: 'center',
    padding: '10px 0',
    backgroundColor: '#e7ae3a',
    color: 'white',
    fontWeight: 'bold',
    marginTop: 0,
    position: 'relative',
    transition: "margin 1s"
  },
  filterIcon: {
    backgroundImage: `url("${icon_url}filter.svg")`,
    display: 'block',
    position: 'absolute',
    top: 12,
    right: 12,
    width: 30,
    height: 30,
    cursor: 'pointer',
    transition: "margin 1s"
  },
  btn: {
    backgroundImage: `url("${icon_url}filter.svg")`,
    display: 'block',
    margin: "25px auto",
    border: 'none',
    backgroundColor: "#666",
    backgroundRepeat: "no-repeat",
    padding: "12px 24px 12px 50px",
    backgroundSize: 15,
    backgroundPosition: "5px center",
    color: 'white',
    fontSize: "17px",
    fontWeight: 'bold',
    cursor: 'pointer'
  },
  image: {
    height: "32px",
    width: "32px",
    pointerEvents: "none"
  }
}


class Filters extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        groups: [],
        categories: [],
        open: false
      }
    }
    handleFilter = (name, value) => {
      const actives = this.state[name];
      const index = actives.indexOf(value);
      if (index > -1) {
        actives.splice(index, 1);
      } else {
        actives.push(value);
      }
      this.setState({[name]:actives});
    }

    handleSubmit = event => {
      event.preventDefault();
      this.props.submit({...this.state});
    }

    toggleFilters = () => {
      this.setState({open:!this.state.open});
    }


    render () {
      const {groups, categories} = this.props;
      const { open } = this.state;
      return (
        <div>
          <h2 style={styles.header} onClick={this.toggleFilters}>Filtrér tilbud<span style={styles.filterIcon}></span></h2>
          {open && (<form className="filters" onSubmit={this.handleSubmit}>
            <div className="filter">
              <h4 style={styles.title}>Varegrupper</h4>
              <div style={styles.filterWrap}>
              {Object.keys(groups).length > 0 && (
                Object.keys(groups).map( (item, index) => (
                <label key={`group-${index}`} htmlFor={groups[item].id} style={styles.label} className={this.state.groups.indexOf(groups[item].id) > -1 ? "active" : ''}>
                  <input type="checkbox" id={groups[item].id} onClick={() => this.handleFilter('groups', groups[item].id)} style={styles.input}/>
                  <img src={`${icon_url}` + groups[item].attributes.field_external_id + `.svg`} alt="" style={styles.image} />
                  <br/>

                  <p style={{padding: "2px"}}>{groups[item].attributes.field_external_id} </p>
                </label>
                ))
              )}
              </div>
            </div>
            <div className="filter">
              <h4 style={styles.title}>Kategorier</h4>
              <div style={styles.filterWrap}>
              { Object.keys(categories).length > 0 && (
                Object.keys(categories).map(item => (
                  <label key={`category-${item}`} htmlFor={item} style={styles.label} className={this.state.categories.indexOf(item) > -1 ? "active" : ''}>
                    <img src={`${icon_url}` + item + `.png`} alt="" style={{height: "32px", width: "32px"}} alt=""/>
                    <br />
                    <input type="checkbox" id={item} onClick={() => this.handleFilter('categories', item)} style={styles.input}/>
                    <p>{categories[item]}</p>
                  </label>
                ))
              )}
              </div>
            </div>

            <input type="submit" value={"FILTRÉR"} style={styles.btn} />
        </form>)}
      </div>
    )
  }


}

export default Filters;
