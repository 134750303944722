import React from "react";
import { getSaving, getSavingLabel, formatPrice } from "../lib/utils";

const Price = ({ entity, design, product, modal }) => {
  const { sales_price, savings_amount, savings_percent, savings_type } = entity.attributes;
  const { price_color } = design.attributes;

  const styles = {
    wrap: {
      color: `#${price_color}`,
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      display: "flex",
      maxHeight: "50%",
      fontFamily: "Oswald, sans-serif",
      lineHeight: 1,
      marginBottom: -2,
      justifyContent: "flex-end"
    },
    wrapDirection: {
      left: {
        flexDirection: "row-reverse"
      }
    },
    price: {
      left: {
        background: `url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20version%3D'1.1'%20preserveAspectRatio%3D'none'%20viewBox%3D'0%200%20100%20100'%3E%20%3Cpath%20d%3D'M0%2C0%20L75%2C20%20L100%2C100%20L0%2C100'%20fill%3D'rgb(231%2C%20175%2C%2057)'%3E%3C%2Fpath%3E%3C%2Fsvg%3E")`
      },
      right: {
        background: `url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20version%3D'1.1'%20preserveAspectRatio%3D'none'%20viewBox%3D'0%200%20100%20100'%3E%20%3Cpath%20d%3D'M%2025%2C25%20L%20100%2C0%20L%20100%2C100%20L%200%2C100'%20fill%3D'rgb(231%2C%20175%2C%2057)'%3E%3C%2Fpath%3E%3C%2Fsvg%3E")`
      }
    },
    savings: {
      padding: "30px 0px 10px 20px",
      color: modal ? "#000000" : "",
    },
    amount: {
      fontWeight: "bold",
      whiteSpace: "nowrap"
    },
    label: {
      fontSize: 21
    },
    pant: {
      fontFamily: "Nunito, sans-serif",
      fontSize: 14,
      lineHeight: 1.5
    }
  };

  return (
    <div style={{ ...styles.wrap, ...styles.wrapDirection[design.attributes.price_placement] }}>
      <div className="priceSavings" style={styles.savings}>
        <p style={styles.label}>{getSavingLabel(savings_type)}</p>
        <p dangerouslySetInnerHTML={{ __html: getSaving(savings_type, savings_amount, savings_percent, design.attributes.price_color) }} style={styles.amount} className="savings-amount" />
        <p style={styles.pant} />
      </div>
      <div className={"priceAmount " + design.attributes.price_placement} style={styles.price[design.attributes.price_placement]}>
        <p style={styles.label}>{product.attributes.sales_unit}</p>
        <p dangerouslySetInnerHTML={{ __html: formatPrice(sales_price, design.attributes.price_color) }} style={styles.amount} className="price-amount" />
        <p style={styles.pant}>{product.attributes.pant && "(Excl. pant)"}</p>
      </div>
    </div>
  );
};

export default Price;
