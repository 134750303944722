import React, { Component } from "react";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroller";
import qs from "qs";

import normalizer from "./lib/normalize";
import { _merge, _sort } from "./lib/Object";
import { getRelation } from "./lib/utils";
import Group from "./components/Group";
import Offer from "./components/Offer";
import Banner from "./components/Banner";
import Filters from "./components/Filters";
import Modal from "./components/Modal";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_data: {},
      show: false,
      hasMore : true,
      categories: [],
      filters: [],
      isFiltering: false,
      groups: [],
      banners: {},
      data: {},
      product_groups: {},
      offers: {},
      next: {
        group: null,
        products: null,
        wait: true
      },
    };
  }

  async componentDidMount() {

    this.setState({hasMore : true});
    await this.apiCall(`${this.props.url}/api/product-group?sort=weight`, "group", this.setGroups);
    const { products, banners } = this.getProductsUrl();

    await this.apiCall(products, "products", this.addOffersMeta);
    await this.apiCall(banners, "banners", this.addBannersMeta);
  }

  setGroups = (data) => {
    if(data["product-group"]) {
      const grps = data["product-group"];
      this.setState({
        product_groups: grps,
      });
    }
  };

  getProductsUrl = () => {
    if(this.state.hasMore === false) {
      this.setState({hasMore: true});
    }

    const { next, data, groups } = this.state;
    if (!next.products) {
      const groupIds = Object.keys(data["product-group"]);
      const group = groupIds[groups.length];
      if (group) {
        const query = {
          filter: {
            "field_related_product.field_group.id": group,
            "offer_type": "global"
          },
          page: {
            limit: this.props.pageLimit
          },
        };

        const bq = {
          filter: {
            "field_group.id": group
          }
        };

        let category_query;

        groups.push(group);
        next.products = `${this.props.url}/api/product-offer?${qs.stringify(query)}`;
        next.banners = `${this.props.url}/api/banner?${qs.stringify(bq)}`;

        //if categories are set to filter, add it to the query when searching for offers
        if(this.state.categories.length > 0) {
          category_query = "&filter[field_related_product.category][operator]=IN"
          Object.keys(this.state.categories).map( (item) => {
            category_query += '&filter[field_related_product.category][value]=' + this.state.categories[item];
          });
          //if no product group filters are given, make a query that returns offers only from the categories
          if(this.state.filters.length > 0) {
            next.products += category_query;
          } else if(this.state.filters.length === 0) {
            next.products = `${this.props.url}/api/product-offer?` + category_query;
          }
        }

        //check from parameter in the url
        const url = window.location.href;
        let params;
        //if 'from' is in the params
        if( (params = url.substring(url.lastIndexOf("?") + 1, url.length)).includes("from") ) {
          params = params.substring(params.lastIndexOf("=") + 1, params.length);
          const time_convert = new Date(params);
          const epoch = time_convert.getTime()/1000.0;
          let publish_date_query = '&filter[publish_date][operator]=<=&filter[publish_date][value]='
                                    + epoch
                                    + '&filter[unpublish_date][operator]=>='
                                    + '&filter[unpublish_date][value]='
                                    + epoch;
          next.products += publish_date_query;
        }

        this.setState({ groups, next });
      }
    }
    return { products: next.products, banners: next.banners };
  };

  addOffersMeta = data => {
    if (!data["product-offer"]) return;

    Object.keys(data["product-offer"]).forEach(uuid => {
      const offer = data["product-offer"][uuid];
      const product = getRelation(offer, data, "field_related_product");
      const group = getRelation(product, data, "field_group");
      offer.meta = {
        group_id: group.id,
        group_name: group.attributes.name,
        group_weight: group.attributes.weight,
        valid_from: offer.attributes.valid_from,
        valid_to: offer.attributes.valid_to,
        weight: offer.attributes.weight
      };
    });
  };

  addBannersMeta = data => {
    if (!data["banner"]) return;
    Object.keys(data["banner"]).forEach(uuid => {
      const banner = data["banner"][uuid];
      const group = getRelation(banner, data, "field_group");
      banner.meta = {
        group_id: group.id,
        group_name: group.attributes.name,
        group_weight: group.attributes.weight,
        valid_from: banner.attributes.valid_from,
        valid_to: banner.attributes.valid_to,
        weight: banner.attributes.weight
      };
    });
  };

  apiCall = async (url, requrestId, callback = null) => {
    let { data } = this.state;
    const response = await axios.get(`${url}`);
    const next = response.data.links.next ? response.data.links.next.href : null;
    const temp = normalizer(response.data);

    if (typeof callback === "function") {
      callback(temp, requrestId);
    }
    _merge(data, temp);
    this.setState({ data, next: { ...this.state.next, [requrestId]: next } });
    return { data, response };
  };

  loadMore = async () => {
    const { products, banners } = this.getProductsUrl();
    if (products) await this.apiCall(products, "products", this.addOffersMeta);
    if (banners && !this.state.isFiltering) await this.apiCall(banners, "banners", this.addBannersMeta);

    if(products === null && this.state.next.products === null) {
      this.setState({hasMore : false});
    }
    if((products || this.state.next.products) && this.state.hasMore === false) {
      this.setState({hasMore : true});
    }

  };

  handleFilterSubmit = async ({groups, categories}) => {
    if(categories.length) {
      this.state.isFiltering = true;
    } else {
      this.state.isFiltering = false;
    }

    const query = {
      filter: {},
      sort: 'weight',
    };
    if (groups.length) {
      query.filter.id = {
        operator: "IN",
        value: groups,
      }
    }

    await this.setState({
      groups: [],
      banners: {},
      data: {},
      offers: {},
      categories,
      next: {
        group: null,
        products: null,
        wait: true
      },
    });

    await this.apiCall(`${this.props.url}/api/product-group?${qs.stringify(query)}`, "group");
    const { products, banners } = this.getProductsUrl();

    //get the response from the api call in res
    let res;
    res = await this.apiCall(products, "products", this.addOffersMeta);

    //if the call received no objects in response, get next groups
    while(res.response.data.data.length === 0) {
      const { products, banners } = this.getProductsUrl();
      //if no offers exist for all product groups selected, don't send a api call
      if(!products) break;
      res = await this.apiCall(products, "products", this.addOffersMeta);
    }

    !this.state.isFiltering && await this.apiCall(banners, "banners", this.addBannersMeta);
  };

  showModal = (data) => {
    this.setState({ show: true, modal_data : data});
  };

  renderModal = (data) => {
    return (<Modal show={this.state.show} handleClose={this.hideModal} data={data} url={this.props.url}/>);
  }

  hideModal = () => {
    this.setState({ show: false, });
  };

  render() {
    //hard coded categories for rendering
    const categories = {
      "frozen": "Frysevare",
      "chilled":"Kølevare",
      "keyhole": "Nøglehulsmærket",
      "ecological":"Økologisk",
      "halal":"Halal",
      "fishmsc":"MSC",
      "danish":"Dansk",
      "eu-ecological":"EU Øko"
    };
    const { data } = this.state;
    const groups = this.state.product_groups;
    let offers = {};
    let currentGroup = null;
    let currentDates = null;
    _merge(offers, data["product-offer"], data["banner"]);
    offers = _sort(offers, [
      { prop: ["meta", "group_weight"], direction: 1 },
      { prop: ["meta", "valid_from"], direction: -1 },
      { prop: ["meta", "valid_to"], direction: 1 },
      { prop: ["meta", "weight"], direction: 1 }
    ]);
    let modal;
    if(this.state.show === true) {
      modal = this.renderModal(this.state.modal_data);
    }

    return (
      <div id="App" className="App" style={{ maxWidth: 625, marginLeft: 'auto', marginRight: 'auto' }}>

        {modal}

      <Filters groups={groups} categories={categories} submit={this.handleFilterSubmit} url={this.props.url} />
        {Object.keys(offers).length > 0 && (
          <InfiniteScroll
            pageStart={0}
            loadMore={this.loadMore}
            hasMore={ this.state.hasMore }
            loader={
              <div className="loader" key="loader" style={{ clear: "both" }}>
                Indlæser tilbud...
              </div>
            }>
            {Object.keys(offers).map((uuid, index) => {
              const offer = offers[uuid];
              const product = getRelation(offer, data, "field_related_product");
              const group = getRelation(offer.type === "banner" ? offer : product, data, "field_group");
              const printGroup = !currentGroup || group.id !== currentGroup.id;
              let valid_from = new Date(Date.parse(offer.attributes.valid_from));
              valid_from = ("0" + valid_from.getDate()).slice(-2) + "."
                           + ("0" + (valid_from.getMonth() + 1)).slice(-2) + "."
                           + valid_from.getFullYear();

              let valid_to = new Date(Date.parse(offer.attributes.valid_to));
              valid_to = ("0" + valid_to.getDate()).slice(-2) + "."
                           + ("0" + (valid_to.getMonth() + 1)).slice(-2) + "."
                           + valid_to.getFullYear();

              const dates = `Gælder fra ${valid_from} til ${valid_to}`;
              const content = [];
              if (printGroup) {
                currentGroup = group;
                currentDates = null;
                content.push(<Group apiEndpoint={`${this.props.url}`} entity={group} data={data} key={group.id} onClick={this.showModal}/>);
              }
              if (!currentDates || currentDates !== dates) {
                currentDates = dates;
                content.push(
                  <div key={`${group.id}-${dates}`} style={{ clear: "both", fontFamily:'Oswald, sans-serif', fontSize: 25, color: '#949496', textAlign: "center" }}>
                    <p>{dates}</p>
                  </div>
                );
              }
              switch (offer.type) {
                case "banner":

                  content.push(<Banner apiEndpoint={`${this.props.url}`} entity={offer} data={data} key={uuid} />);
                  break;

                default:
                  content.push(<Offer apiEndpoint={`${this.props.url}`} entity={offer} data={data} key={uuid} show={this.showModal}/>);
                  break;
              }
              return <React.Fragment key={index}>{content.map(el => el)}</React.Fragment>;
            })}
          </InfiniteScroll>
        )}
      </div>
    );
  }
}

export default App;
