import React from "react";
import Brand from "./Brand";
import MainImage from "./MainImage";
import Price from "./Price";
import { getRelation, getImageUrl } from "../lib/utils";
import qs from 'qs';

const Offer = ({ apiEndpoint, entity, data, show }) => {
  const product = getRelation(entity, data, "field_related_product");
  const brandMedia = getRelation(product, data, "field_media_brand");
  const design = getRelation(entity, data, "field_offer_design");
  const media = getRelation(design, data, "field_media_main_image");
  const background = getImageUrl(design, "background_image", `crop_${design.attributes.offer_size}`);
  const { text_placement, text_color } = design.attributes;
  const search = qs.parse(window.location.search.replace('?', ''));
  const size = design.attributes.offer_size.split("x");

  let width = "100%";
  let handleShow = (event) => {
    event.preventDefault();
    const offer_width = styles.wrap.width;
    show({product, entity, design, brandMedia, apiEndpoint, offer_width});
  }

  switch (design.attributes.offer_size) {
    case "1x2":
      width = "50%";
      break;

    default:
      width = "100%";
  }

  const styles = {
    wrap: {
      width: width,
      float: "left",
      padding: 5,
      backgroundColor: "#f2f2f2",
      color: `#${text_color}`,
      cursor: "pointer"
    },
    offer: {
      width: "100%",
      paddingTop: `${(size[1] / size[0]) * 100}%`,
      overflow: "hidden",
      position: "relative",
      backgroundImage: `url("${apiEndpoint}${background}")`,
      backgroundPosition: "center",
      backgroundSize: "contain"
    },
    header: {
      position: "absolute",
      top: 0,
      left: 0,
      fontSize: 24,
      width: "100%",
      zIndex: 99,
    },
    title: {
      float: text_placement === "left" ? "left" : "right",
    },
    title_text: {
      float: text_placement === "left" ? "left" : "right",
    }
  };

  return (
    <div style={styles.wrap} onClick={handleShow} className={"offer " + entity.meta.group_name + " " + size}>
      <div style={styles.offer}>
        <div className="offerWrap" style={styles.header}>
          <h2 className="offerHeader" style={styles.title}>{product.attributes.title}</h2>
          <p className="offerText" style={styles.title_text}>{product.attributes.subtitle}</p>
        </div>
        {MainImage && <MainImage apiEndpoint={apiEndpoint} media={media} design={design} />}
        {brandMedia && <Brand apiEndpoint={apiEndpoint} media={brandMedia} design={design} />}
        <Price entity={entity} design={design} product={product} />
      </div>
      {search['print'] && <img src={search['print']} alt="" style={{width: '100%'}}/>}
    </div>
  );
};

export default Offer;
