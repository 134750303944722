import React from "react";
import { getImageUrl } from "../lib/utils";

const MainImage = ({ apiEndpoint, media, design, ...props }) => {
  const { offset_left, offset_top, main_width } = design.attributes;
  const image = main_width !== 0 && getImageUrl(media, "image", "product_design_main_image");

  const style = {
    position: "absolute",
    top: `${offset_top}%`,
    left: `${offset_left}%`,
    width: `${main_width}%`,
    transform: "translate(-50%,-50%)"
  };

  return image && <img className="mainImage" style={style} src={`${apiEndpoint}${image}`} alt="" />;
};

export default MainImage;
