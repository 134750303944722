import { _merge } from "./Object";

function wrap(json) {
  if (Array.isArray(json)) {
    return json;
  }

  return [json];
}

function extractRelationships(relationships) {
  const ret = {};
  Object.keys(relationships).forEach(key => {
    const relationship = relationships[key];
    const name = key;
    ret[name] = {};

    if (typeof relationship.data !== "undefined") {
      if (Array.isArray(relationship.data)) {
        ret[name].data = relationship.data.map(e => ({
          id: e.id,
          type: e.type
        }));
      } else if (relationship.data) {
        ret[name].data = {
          id: relationship.data.id,
          type: relationship.data.type
        };
        if (typeof relationship.data.meta !== "undefined") {
          ret[name].data.meta = relationship.data.meta;
        }
      } else {
        ret[name].data = relationship.data;
      }

      if (typeof relationship.meta !== "undefined") {
        ret[name].meta = relationship.meta;
      }
    }

    if (relationship.links) {
      ret[name].links = relationship.links;
    }
  });
  return ret;
}

function extractEntities(json, root = null) {
  const ret = {};
  wrap(json).forEach(elem => {
    const type = elem.type;
    const entity = type.split("--");

    ret[type] = ret[type] || {};
    ret[type][elem.id] = ret[type][elem.id] || {
      id: elem.id
    };
    ret[type][elem.id].type = elem.type;
    ret[type][elem.id].entity_type = entity[0];
    ret[type][elem.id].root = root;
    ret[type][elem.id].template_suggestions = [];
    ret[type][elem.id].template_suggestions.push("entity");
    ret[type][elem.id].template_suggestions.push(ret[type][elem.id].entity_type);
    if (entity[1]) {
      ret[type][elem.id].bundle = entity[1].replace(new RegExp("_ ", "g"), "-");
      ret[type][elem.id].template_suggestions.push(`${ret[type][elem.id].entity_type}--${ret[type][elem.id].bundle}`);
    }

    ret[type][elem.id].attributes = elem.attributes;

    if (elem.links) {
      ret[type][elem.id].links = {};

      Object.keys(elem.links).forEach(key => {
        const newKey = key;
        ret[type][elem.id].links[newKey] = elem.links[key];
      });
    }

    if (elem.relationships) {
      ret[type][elem.id].relationships = extractRelationships(elem.relationships);
    }

    if (elem.meta) {
      ret[type][elem.id].meta = elem.meta;
    }
  });

  return ret;
}

function doFilterEndpoint(endpoint) {
  return endpoint.replace(/\?.*$/, "");
}

function extractMetaData(json, endpoint, { filterEndpoint }) {
  const ret = {};

  ret.meta = {};

  let metaObject;

  if (!filterEndpoint) {
    const filteredEndpoint = doFilterEndpoint(endpoint);

    ret.meta[filteredEndpoint] = {};
    ret.meta[filteredEndpoint][endpoint.slice(filteredEndpoint.length)] = {};
    metaObject = ret.meta[filteredEndpoint][endpoint.slice(filteredEndpoint.length)];
  } else {
    ret.meta[endpoint] = {};
    metaObject = ret.meta[endpoint];
  }

  metaObject.data = {};

  if (json.data) {
    const meta = [];

    wrap(json.data).forEach(object => {
      const pObject = {
        id: object.id,
        type: object.type
      };

      if (object.relationships) {
        pObject.relationships = extractRelationships(object.relationships);
      }

      meta.push(pObject);
    });

    metaObject.data = meta;
  }

  if (json.links) {
    metaObject.links = json.links;
    ret.meta[doFilterEndpoint(endpoint)].links = json.links;
  }

  if (json.meta) {
    metaObject.meta = json.meta;
  }

  return ret;
}

export default function normalize(json, opts = {}) {
  const ret = {};
  const { endpoint } = opts;
  let { filterEndpoint } = opts;

  if (typeof filterEndpoint === "undefined") {
    filterEndpoint = true;
  }

  if (json.data) {
    _merge(ret, extractEntities(json.data));
  }

  if (json.included) {
    _merge(ret, extractEntities(json.included, { type: json.data.type, id: json.data.id }));
  }

  if (endpoint) {
    const endpointKey = filterEndpoint ? doFilterEndpoint(endpoint) : endpoint;

    _merge(
      ret,
      extractMetaData(json, endpointKey, {
        filterEndpoint
      })
    );
  }

  return ret;
}
