import React from "react";
import { getImageUrl, getRelation } from "../lib/utils";

const Banner = ({ apiEndpoint, entity, data }) => {
  const size = entity.attributes.offer_size.split("x");
  const img_url = entity.attributes.image_url;
  const bg_image = getRelation(entity, data, "field_media_background");
  const image = getImageUrl(bg_image, "image", `crop_${entity.attributes.offer_size}`);

  let width = "100%";

  switch (entity.attributes.offer_size) {
    case "1x2":
      width = "50%";
      break;

    default:
      width = "100%";
  }

  const styles = {
    wrap: {
      width: width,
      float: "left",
      padding: 5,
      backgroundColor: "#f2f2f2"
    },
    offer: {
      width: "100%",
      paddingTop: `${(size[1] / size[0]) * 100}%`,
      overflow: "hidden",
      position: "relative",
      backgroundImage: `url("${apiEndpoint}${image}")`,
      backgroundPosition: "center",
      backgroundSize: "contain"
    }
  };

  return (
    <div style={styles.wrap}>
      <a href={img_url}>
        <div style={styles.offer}>
        </div>
      </a>
    </div>
  );
};

export default Banner;
