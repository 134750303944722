import React from "react";
import Price from "./Price";
import Brand from "./Brand";

const Modal = ({ handleClose, show, children, data, url }) => {
  const icon_url = `https://s-engros.dk/wp-content/themes/fsd/elements/tilbud/icons/`;
  const showHideClassName = show ? 'modal display-block' : 'modal display-none';
  const {entity, design, product, brandMedia, apiEndpoint, offer_width} = data;
  const title = product.attributes.title;
  const subtitle = product.attributes.subtitle;
  const product_number = product.attributes.product_number;
  const categories = [];
  let publish_date = new Date(entity.attributes.valid_from);
  publish_date = ("0" + publish_date.getDate()).slice(-2) + "."
               + ("0" + (publish_date.getMonth() + 1)).slice(-2) + "."
               + publish_date.getFullYear();

  let unpublish_date = new Date(entity.attributes.valid_to);
  unpublish_date = ("0" + unpublish_date.getDate()).slice(-2) + "."
                 + ("0" + (unpublish_date.getMonth() + 1)).slice(-2) + "."
                 + unpublish_date.getFullYear();

  const width = document.getElementById('App').offsetWidth;
  if(product.attributes.category.length > 0) {
    for(const category of product.attributes.category) {
      categories.push(category);
    }
  }
  const styles = {
    close : {
      float : "right",
      background: "none",
      border: "none",
      cursor: 'pointer',
      marginTop: 10,
      marginRight: 10
    },
    modal : {
      position: "absolute",
      width: width,
    },
    title : {
      fontFamily : "Oswald, sans-serif",
      fontSize : 25,
    },
    small_text : {
      fontFamily : "Oswald, sans-serif",
      fontSize : 14,
    },
    wrap : {
      padding : "20px",
      color : "#949496",
    },
    closeButton: {},
    closeIcon: {
      width: 20,
    },
    brand: {
      left: 'auto',
      top: 'auto',
      right: 10,
      bottom: 10,
      transform: 'unset',
      maxHeight: "100px"
    },
    dates : {
      fontFamily : "Oswald, sans-serif",
      fontSize : 14,
      paddingTop: "5px",
      paddingBottom: "5px",
      fontWeight: "bold"
    }
  }

  const strictDesign = {
    ...design,
    attributes: {
      ...design.attributes,
      price_placement: 'left',
      brand_width: 20,
    }
  }

  return (
    <div className={showHideClassName} >
      <section className='modal-main' style={styles.modal}>
        <button style={styles.close} onClick={handleClose} >
          <img src={`${icon_url}close.svg`} style={styles.closeIcon}/>
        </button>

        <div style={styles.wrap}>
        <p style={styles.small_text}>Varenummer {product_number}</p>
        <p style={styles.title}>{title}</p>
        <p style={styles.small_text}>{subtitle}</p>

        <p style={styles.dates}>Gælder fra {publish_date} til {unpublish_date}</p>

        {Object.keys(categories).map( (category) => (
          <div key={category}>
            <img src={`${icon_url}` + categories[category] + `.png`} alt="" style={{height: "32px", width: "32px"}} alt=""/>
          </div>
        ))}

        </div>
        {brandMedia && <Brand media={brandMedia} design={strictDesign} apiEndpoint={apiEndpoint} style={styles.brand} />}
        <Price entity={entity} design={strictDesign} product={product} modal={true} />
      </section>
    </div>
  );
};

export default Modal;
