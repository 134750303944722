export const getRelation = (entity, data, field) => {
  const relation = entity && entity.relationships[field] && entity.relationships[field].data;
  return relation && data[relation.type] && data[relation.type][relation.id];
};

export const getImageUrl = (entity, field, style) => {
  const relation = entity && entity.relationships[field] && entity.relationships[field].data;
  return relation && relation.meta && relation.meta.derivatives[style] && relation.meta.derivatives[style].url;
};

export const formatPrice = (price, text_color) => {
  const components = price.split(".");
  const coma = components[1] === "00" ? "," : "";
  return `${components[0]}${coma}<sup class="${text_color} ${components[1] !== "00" ? " no-dash" : ""}">${components[1] !== "00" ? components[1] : "&nbsp;"}</sup>`;
};

export const formatPercent = percent => {
  if(percent) {
    const components = percent.split(".");
    return `${components[0]}<span>%</span>`;
  }
};

export const getSavingLabel = type => {
  switch (type) {
    case "price":
    case "percent":
      return "Spar";

    case "up_to":
    case "up_to_percent":
      return "Spar op til";

    default:
      return "";
  }
};

export const getSaving = (type, amount = 0.0, percent = 0.0, text_color) => {
  switch (type) {
    case "price":
    case "up_to":
      return formatPrice(amount, text_color);

    case "percent":
    case "up_to_percent":
      return formatPrice(percent);

    default:
      return "";
  }
};
