export const _get = (object, path, fallback = null) => {
  if (path.length <= 0 || !_isObject(object)) return object;
  const prop = path.shift();
  if (object.hasOwnProperty(prop)) {
    return _get(object[prop], path);
  }
  return fallback;
};

export const _isObject = item => {
  return item && typeof item === "object" && !Array.isArray(item);
};

export const _merge = (target, ...objects) => {
  if (!_isObject(target)) return target;

  while (objects.length) {
    const source = objects.shift();
    if (_isObject(source)) {
      for (let key in source) {
        if (!target.hasOwnProperty(key)) Object.assign(target, { [key]: source[key] });
        else if (_isObject(source[key])) _merge(target[key], source[key]);
      }
    }
  }

  return target;
};

export const _sort = (target, sortBy) => {
  const sorted = {};
  const keys = Object.keys(target).sort((keyA, keyB) => {
    let i = 0;
    let result = 0;
    const a = target[keyA];
    const b = target[keyB];
    while (i < sortBy.length && result === 0) {
      let criteria = sortBy[i];
      let { direction, prop } = criteria;

      if (typeof prop == "string") prop = [prop];

      let propA = _get(a, [...prop], "");
      let propB = _get(b, [...prop], "");

      result = direction * (propA < propB ? -1 : propA > propB ? 1 : 0);
      i++;
    }
    return result;
  });
  keys.map(key => (sorted[key] = target[key]));

  return sorted;
};
