import React from "react";
import { getImageUrl } from "../lib/utils";

const Brand = ({ apiEndpoint, media, design, ...props }) => {
  const { brand_offset_left, brand_offset_top, brand_width } = design.attributes;
  const image = brand_width !== 0 && getImageUrl(media, "image", "product_design_brand_image");

  const defaultStyle = {
    position: "absolute",
    top: `${brand_offset_top}%`,
    left: `${brand_offset_left}%`,
    width: `${brand_width}%`,
    transform: "translate(-50%,-50%)"
  };

  const style = {...defaultStyle, ...props.style}

  return image && <img style={style} src={`${apiEndpoint}${image}`} alt="" />;
};

export default Brand;
